exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alapszabaly-js": () => import("./../../../src/pages/alapszabaly.js" /* webpackChunkName: "component---src-pages-alapszabaly-js" */),
  "component---src-pages-beszamolok-js": () => import("./../../../src/pages/beszamolok.js" /* webpackChunkName: "component---src-pages-beszamolok-js" */),
  "component---src-pages-celok-js": () => import("./../../../src/pages/celok.js" /* webpackChunkName: "component---src-pages-celok-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-hasznos-linkek-es-hirek-js": () => import("./../../../src/pages/hasznos-linkek-es-hirek.js" /* webpackChunkName: "component---src-pages-hasznos-linkek-es-hirek-js" */),
  "component---src-pages-hirek-dato-cms-news-item-slug-js": () => import("./../../../src/pages/hirek/{datoCmsNewsItem.slug}.js" /* webpackChunkName: "component---src-pages-hirek-dato-cms-news-item-slug-js" */),
  "component---src-pages-hirek-js": () => import("./../../../src/pages/hirek.js" /* webpackChunkName: "component---src-pages-hirek-js" */),
  "component---src-pages-hirlevel-feliratkozas-js": () => import("./../../../src/pages/hirlevel-feliratkozas.js" /* webpackChunkName: "component---src-pages-hirlevel-feliratkozas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jelentkezes-js": () => import("./../../../src/pages/jelentkezes.js" /* webpackChunkName: "component---src-pages-jelentkezes-js" */),
  "component---src-pages-kapcsolat-js": () => import("./../../../src/pages/kapcsolat.js" /* webpackChunkName: "component---src-pages-kapcsolat-js" */),
  "component---src-pages-kiadvanyok-js": () => import("./../../../src/pages/kiadvanyok.js" /* webpackChunkName: "component---src-pages-kiadvanyok-js" */),
  "component---src-pages-kialtvany-js": () => import("./../../../src/pages/kialtvany.js" /* webpackChunkName: "component---src-pages-kialtvany-js" */),
  "component---src-pages-koordinacios-kozpont-js": () => import("./../../../src/pages/koordinacios-kozpont.js" /* webpackChunkName: "component---src-pages-koordinacios-kozpont-js" */),
  "component---src-pages-partolo-tagok-js": () => import("./../../../src/pages/partolo-tagok.js" /* webpackChunkName: "component---src-pages-partolo-tagok-js" */),
  "component---src-pages-programok-js": () => import("./../../../src/pages/programok.js" /* webpackChunkName: "component---src-pages-programok-js" */),
  "component---src-pages-tagjaink-js": () => import("./../../../src/pages/tagjaink.js" /* webpackChunkName: "component---src-pages-tagjaink-js" */),
  "component---src-pages-tamogatas-js": () => import("./../../../src/pages/tamogatas.js" /* webpackChunkName: "component---src-pages-tamogatas-js" */),
  "component---src-pages-tamogatott-eletvitel-es-lakhatas-js": () => import("./../../../src/pages/tamogatott-eletvitel-es-lakhatas.js" /* webpackChunkName: "component---src-pages-tamogatott-eletvitel-es-lakhatas-js" */)
}

